import { Component, Input, OnInit } from '@angular/core';
import { ArrayOfManufacturers, Manufacturer } from '../../models/manufacturar.interface';
import { Observable } from 'rxjs';
import { BreakpointState } from '@angular/cdk/layout';


@Component({
  selector: 'app-manufacturers',
  templateUrl: './manufacturers.component.html',
  styleUrls: ['./manufacturers.component.scss']
})
export class ManufacturersComponent implements OnInit {
  @Input() isSmallScreen: Observable<BreakpointState>;

  private manufacturers: Manufacturer[] = [
    {
      name: 'Sandoz Bienestar',
      image: 'assets/images/manufacturers/sandoz.jpg',
      url: 'https://www.farmacias.com/comprar/marcas/723-sandoz-bienestar'
    },
    {
      name: 'Aquilea',
      image: 'assets/images/manufacturers/aquilea.jpg',
      url: 'https://www.farmacias.com/comprar/marcas/102-aquilea'
    },
    {
      name: 'Vitis',
      image: 'assets/images/manufacturers/vitis.jpg',
      url: 'https://www.farmacias.com/comprar/marcas/47-vitis'
    },
    {
      name: 'Durex',
      image: 'assets/images/manufacturers/durex.jpg',
      url: 'https://www.farmacias.com/comprar/marcas/51-durex'
    },
    {
      name: 'Vichy',
      image: 'assets/images/manufacturers/vichy.png',
      url: 'https://www.farmacias.com/comprar/marcas/100-vichy'
    },
    {
      name: 'Mustela',
      image: 'assets/images/manufacturers/mustela.jpg',
      url: 'https://www.farmacias.com/comprar/marcas/35-mustela'
    }
  ];

  sortedManufacturers: ArrayOfManufacturers = [];

  constructor() {}

  ngOnInit() {
    this.isSmallScreen.subscribe(observer => {
      if (observer.breakpoints['(max-width: 599.99px)']) {
        this.sortedManufacturers = this.chunkArray(this.manufacturers, 1);
      } else if (observer.matches) {
        this.sortedManufacturers = this.chunkArray(this.manufacturers, 3);
      } else {
        this.sortedManufacturers = this.chunkArray(this.manufacturers, 6);
      }
    });
  }

  chunkArray(fullArray: Manufacturer[], positions: number): ArrayOfManufacturers {
    const chunks: ArrayOfManufacturers = [];
    let int = 0;
    const size = fullArray.length;
    while (int < size) {
      chunks.push(fullArray.slice(int, (int += positions)));
    }
    return chunks;
  }
}
