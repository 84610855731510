import { Component } from '@angular/core';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent {

  menu: any[] = [
    {
      name: 'Cosmética',
      url: 'https://www.farmacias.com/comprar/cosmetica-7'
    },
    {
      name: 'Higiene',
      url: 'https://www.farmacias.com/comprar/higiene-8'
    },
    {
      name: 'Nutrición dietética',
      url: 'https://www.farmacias.com/comprar/nutricion-dietetica-4'
    },
    {
      name: 'Salud',
      url: 'https://www.farmacias.com/comprar/salud-3'
    },
    {
      name: 'Bebé y mamá',
      url: 'https://www.farmacias.com/comprar/bebe-y-mama-6'
    },
    {
      name: 'Herbolario',
      url: 'https://www.farmacias.com/comprar/herbolario-5'
    },
    {
      name: 'Protección solar',
      url: 'https://www.farmacias.com/comprar/proteccion-solar-9'
    }
  ];

  constructor() { }

}
