import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { QueryResult } from './models/result.interface';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  constructor(private http: HttpClient) {}

  subscribeNewsletter(email: string): Observable<any> {
    return this.http.post<any>(`https://www.farmacias.com/recursos/scripts/formulario_suscribe_blogger.php`, {
      category: 'marketing',
      email: email
    });
  }

  getProducts(query: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'eu1-4b4ec30e9dd4f74766db1d5f27a502052a87ac61'
      })
    };
    return this.http.get<QueryResult>(
      `https://eu1-search.doofinder.com/5/search?hashid=99f68ab824993130a4b554cc353a8eb0&query=${query}`,
      httpOptions
    );
  }
}

