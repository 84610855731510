import { Component, Input, OnInit } from '@angular/core';
import { ArrayOfProductos, Product } from '../../models/product.interface';
import { Observable } from 'rxjs';
import { BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {
  @Input() isSmallScreen: Observable<BreakpointState>;

  private products: Product[] = [
    {
      name: 'Nutribén Natal Pro-A leche de inicio 800gr',
      image: '/assets/images/products/mock/nutriben-natal-pro-a-leche-de-inicio-800-gr.jpg',
      discount: '-17%',
      url: 'https://www.farmacias.com/comprar/6409-nutriben-natal-pro-a-leche-de-inicio-800-gr'
    },
    {
      name: 'Sandoz Bienestar Drenante 15 Ampollas',
      image: '/assets/images/products/mock/sandoz-bienestar-drenante-15-ampollas.jpg',
      discount: '-30%',
      url: 'https://www.farmacias.com/comprar/6838-sandoz-bienestar-drenante-15-ampollas'
    },
    {
      name: 'Weleda bebé crema pañal de caléndula 75ml',
      image: '/assets/images/products/mock/weleda-bebe-crema-panal-de-calendula-75ml.jpg',
      discount: '-12%',
      url: 'https://www.farmacias.com/comprar/9469-weleda-bebe-crema-panal-de-calendula-75ml'
    },
    {
      name: 'Aquilea melatonina 60 comprimidos',
      image: '/assets/images/products/mock/aquilea-melatonina-60-comprimidos.jpg',
      discount: '-20%',
      url: 'https://www.farmacias.com/comprar/5352-aquilea-melatonina-60-comprimidos'
    },
    {
      name: 'Corega Crema Extra Fuerte 40ml',
      image: '/assets/images/products/mock/corega-crema-extra-fuerte-40ml.jpg',
      discount: '-22%',
      url: 'https://www.farmacias.com/comprar/9319-corega-crema-extra-fuerte-40ml'
    },
    {
      name: 'Hero Baby Leche Pedialac 1 800 gr',
      image: '/assets/images/products/mock/hero-baby-leche-pedialac-1-800-gr.jpg',
      discount: '-15%',
      url: 'https://www.farmacias.com/comprar/4587-hero-baby-leche-pedialac-1-800-gr'
    },
    {
      name: 'Mitosyl Pomada Protectora 2 x 65 gr',
      image: '/assets/images/products/mock/mitosyl-pomada-protectora-2-x-65-gr.jpg',
      discount: '-26%',
      url: 'https://www.farmacias.com/comprar/612008-mitosyl-pomada-protectora-2-x-65-gr'
    },
    {
      name: 'Redoxon Inmuno 4 14 Sobres',
      image: '/assets/images/products/mock/redoxon-inmuno-4-14-sobres.jpg',
      discount: '20%',
      url: 'https://www.farmacias.com/comprar/3619-redoxon-inmuno-4-14-sobres'
    }
  ];

  public sortedProducts: ArrayOfProductos = [];

  constructor() {}

  ngOnInit() {
    this.isSmallScreen.subscribe(observer => {
      if (observer.breakpoints['(max-width: 599.99px)']) {
        this.sortedProducts = this.chunkArray(this.products, 1);
      } else if (observer.matches) {
        this.sortedProducts = this.chunkArray(this.products, 2);
      } else {
        this.sortedProducts = this.chunkArray(this.products, 4);
      }
    });
  }

  chunkArray(fullArray: Product[], positions: number) {
    const chunks: ArrayOfProductos = [];
    let int = 0;
    const size = fullArray.length;
    while (int < size) {
      chunks.push(fullArray.slice(int, (int += positions)));
    }
    return chunks;
  }
}
