import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { LayoutModule } from '@angular/cdk/layout';

import { registerLocaleData } from '@angular/common';
import esEs from '@angular/common/locales/es';
import { LOCALE_ID } from '@angular/core';

import { AppComponent } from './app.component';
import { AppService } from './app.service';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { InformationComponent } from './components/information/information.component';
import { CategoriesComponent } from './components/categories/categories.component';
import { ProductsComponent } from './components/products/products.component';
import { BannersComponent } from './components/banners/banners.component';
import { ManufacturersComponent } from './components/manufacturers/manufacturers.component';
import { CouponsComponent } from './components/coupons/coupons.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { CookiesMessageComponent } from './components/cookies-message/cookies-message.component';

import { SwiperModule } from 'ngx-swiper-wrapper';
import { SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  a11y: false,
  direction: 'horizontal',
  slidesPerView: 1,
  keyboard: false,
  mousewheel: false,
  scrollbar: false,
  navigation: true,
  pagination: true
};

registerLocaleData(esEs);

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    InformationComponent,
    CategoriesComponent,
    ProductsComponent,
    BannersComponent,
    ManufacturersComponent,
    CouponsComponent,
    NavigationComponent,
    CookiesMessageComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    HttpClientModule,
    MatButtonModule,
    MatRippleModule,
    MatInputModule,
    MatCheckboxModule,
    DragDropModule,
    MatListModule,
    MatIconModule,
    MatSidenavModule,
    LayoutModule,
    SwiperModule
  ],
  providers: [
    AppService,
    {
      provide: LOCALE_ID,
      useValue: 'es-Es'
    },
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
