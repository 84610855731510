import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {
  @Input() isSmallScreen: Observable<BreakpointState>;

  private categories: any[] = [
    {
      name: 'Cosmética',
      image: '/assets/images/categories/cosmetica.png',
      url: 'https://www.farmacias.com/comprar/cosmetica-7'
    },
    {
      name: 'Nutrición',
      image: '/assets/images/categories/nutricion.png',
      url: 'https://www.farmacias.com/comprar/higiene-8'
    },
    {
      name: 'Salud',
      image: '/assets/images/categories/salud.png',
      url: 'https://www.farmacias.com/comprar/salud-3'
    },
    {
      name: 'Bebé y mamá',
      image: '/assets/images/categories/bebe-y-mama.png',
      url: 'https://www.farmacias.com/comprar/bebe-y-mama-6'
    },
    {
      name: 'Higiene',
      image: '/assets/images/categories/higiene.png',
      url: 'https://www.farmacias.com/comprar/higiene-8'
    },
    {
      name: 'Herbolario',
      image: '/assets/images/categories/herbolario.png',
      url: 'https://www.farmacias.com/comprar/herbolario-5'
    },
    {
      name: 'Marcas',
      image: '/assets/images/categories/marcas.png',
      url: 'https://www.farmacias.com/comprar/marcas'
    },
    {
      name: 'Crema solar',
      image: '/assets/images/categories/solar.png',
      url: 'https://www.farmacias.com/comprar/proteccion-solar-9'
    },
    {
      name: 'Ofertas',
      image: '/assets/images/categories/ofertas.png',
      url: 'https://www.farmacias.com/promociones'
    }
  ];

  sortedCategories: any[] = [];

  constructor() {}

  ngOnInit() {
    this.isSmallScreen.subscribe(observer => {
      if (observer.breakpoints['(max-width: 599.99px)']) {
        this.sortedCategories = this.chunkArray(this.categories, 1);
      } else if (observer.matches) {
        this.sortedCategories = this.chunkArray(this.categories, 3);
      } else {
        this.sortedCategories = this.chunkArray(this.categories, 6);
      }
    });
  }

  private chunkArray(fullArray, positions) {
    const chunks = [];
    let int = 0;
    const size = fullArray.length;
    while (int < size) {
      chunks.push(fullArray.slice(int, (int += positions)));
    }
    return chunks;
  }
}
