import { Component, Inject } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import { AppService } from '../../app.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  newsletterForm: FormGroup;
  newsletterError: boolean = false;
  newsletterErrorText: string = '';
  
  constructor(
    private service: AppService,
    @Inject(DOCUMENT) private document: HTMLDocument,
    private formBuilder: FormBuilder
  ) {
    this.newsletterForm = this.formBuilder.group({
      email: '',
      allow: false
    });
  }

  newsletterSubmit() {
    if(this.newsletterForm.get('email').value && this.newsletterForm.get('allow').value) {
      this.newsletterError = false;
      this.newsletterErrorText = '';
      this.service.subscribeNewsletter(this.newsletterForm.get('email').value).subscribe(
        data => {
          const entry = 'suscripcion-completada-salud';
          this.document.location.href = `https://www.farmacias.com/gracias?entrada=${entry}`;
        },
        err => {
          this.newsletterError = true;
          this.newsletterErrorText = 'Ha ocurrido un error inesperado, inténtelo de nuevo más tarde o póngase en contacto con nostros.';    
        }
      );  
    } else {
      this.newsletterError = true;
      this.newsletterErrorText = 'Por favor, para continuar introduce tu correo electrónico y acepta las condiciones de uso.';
    }
  } 
}
