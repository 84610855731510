import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-banners',
  templateUrl: './banners.component.html',
  styleUrls: ['./banners.component.scss']
})
export class BannersComponent implements OnInit {

  banners: any[] = [
    {
      name: 'Alimentación infantil',
      image: '/assets/images/banners/banner-alimentacion-infantil.jpg',
      url: 'https://www.farmacias.com/comprar/bebe-y-mama-6/alimentacion-para-bebe-15'
    },
    {
      name: 'Cosmética facial',
      image: '/assets/images/banners/banner-cosmetica-facial.jpg',
      url: 'https://www.farmacias.com/comprar/cosmetica-7/cosmetica-facial-22'
    }
  ];
  
  constructor() { }

  ngOnInit() { }

}
