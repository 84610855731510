import { Component, Output, EventEmitter, ViewChild, ElementRef, OnInit } from '@angular/core';

@Component({
  selector: 'app-information',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.scss']
})
export class InformationComponent implements OnInit {
  @Output() search = new EventEmitter();
  @Output() setInformationComponentHTMLElement = new EventEmitter<any>();
  @Output() scrollToInputSearch = new EventEmitter();
  @ViewChild('anchorSearch') anchor: ElementRef<HTMLElement>;
  @ViewChild('inputSearch') input: ElementRef<HTMLElement>;

  constructor() {}

  ngOnInit() {
    this.setInformationComponentHTMLElement.emit({ anchor: this.anchor, input: this.input });
  }

  onInputSearchClick() {
    this.scrollToInputSearch.emit();
  }
}
