import { Component, OnInit } from '@angular/core';
import { CookieService } from '../../cookie.service';


@Component({
  selector: 'app-cookies-message',
  templateUrl: './cookies-message.component.html',
  styleUrls: ['./cookies-message.component.scss']
})
export class CookiesMessageComponent implements OnInit {
  public areCookiesAllowed: boolean;


  constructor(private cookies: CookieService) {
    this.areCookiesAllowed = true;
  }

  ngOnInit() {
    if (this.cookies.get('polcks') === 'si') {
      this.areCookiesAllowed = true;
    } else {
      this.areCookiesAllowed = false;
    }
  }

  allowCookies() {
    this.cookies.put('polcks', 'si');
    this.areCookiesAllowed = true;
  }
}
