import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class CookieService {
  private cookieStore = {};

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    if (isPlatformBrowser(this.platformId)) {
      // Client only code.
      this.parseCookies(document.cookie);
    }
    if (isPlatformServer(this.platformId)) {
      // Server only code.
    }
  }

  public parseCookies(cookies) {
    this.cookieStore = {};
    if (!!cookies === false) {
      return;
    }
    let cookiesArr = cookies.split(';');
    for (const cookie of cookiesArr) {
      const cookieArr = cookie.split('=');
      this.cookieStore[cookieArr[0]] = cookieArr[1];
    }
  }

  // get(key: string) {
  //   console.log(this.cookieStore, key);
  //   return !!this.cookieStore[key] ? this.cookieStore[key] : null;
  // }

  get(cname: string) {
    if (isPlatformBrowser(this.platformId)) {
      // Client only code.
      const name = cname + '=';
      const decodedCookie = decodeURIComponent(document.cookie);
      const ca = decodedCookie.split(';');
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return '';
    } else {
      return '';
    }
  }

  put(key: string, value: string) {
    if (isPlatformBrowser(this.platformId)) {
      // Client only code.
      document.cookie = key + '=' + value;
    }
  }
}
