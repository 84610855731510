import { Component, OnInit, ElementRef } from '@angular/core';
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  openedSidenav: boolean;
  isSmallScreen: Observable<BreakpointState>;
  anchorSearch: ElementRef<HTMLElement>;
  inputSearch: ElementRef<HTMLElement>;

  constructor(public breakpointObserver: BreakpointObserver) {
    this.openedSidenav = false;
  }

  ngOnInit() {
    this.isSmallScreen = this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium]);
  }

  toggleSidenav() {
    this.openedSidenav = !this.openedSidenav;
  }

  setInformationComponentHTMLElement(elements: any) {
    this.anchorSearch = elements.anchor;
    this.inputSearch = elements.input;
  }

  scrollToInputSearch() {
    this.inputSearch.nativeElement.focus();
    this.anchorSearch.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }
}
